// Radiopicker switch
$('.btn-switch-wp .label').on('click', function() {
  $(this).prev().find('.checkbox-toggle').trigger('click');
});

$('.trigger-switch').on('click', function(){
  $('.btn-switch-wp .checkbox-toggle').trigger('click');
});


$('#job-search').on('submit', function (e) {
  $.ajax({
    url: $('#job-search').data('url'),
    data: $('#job-search').serialize()
  }).done(function (data) {
    $('#job-list').html(data);
  });

  e.preventDefault();
});

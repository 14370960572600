// JS Dependency
var parsleyjs = require('parsleyjs');

var lang = $('body').attr('data-lang');
if(lang=='fr'){
    var parsleyjs_fr = require('parsleyjs/dist/i18n/fr.js');
}

// Initialisation
if ($('.parsley-validate').length) {
    $('.parsley-validate').parsley();
}

// JS dependencies
var bootstrapselect = require('bootstrap-select');

// CSS dependency
import '../../node_modules/bootstrap-select/dist/css/bootstrap-select.css';

// Initialisation
$('.selectpicker:not(.search-disabled)').selectpicker({
  container: 'body',
  liveSearch: true,
  noneResultsText: 'Aucun résultat trouvé'
});

// Initialisation
$('.selectpicker.search-disabled').selectpicker({
  container: 'body',
  liveSearch: false,
});

$('.selectpicker').on('changed.bs.select', function(e){
  $(this).parent().addClass('changed');
});

// Navigation mobile
function nav_mobile() {
  $('#nav-mobile-trigger').on('click', function() {
    $('body').toggleClass('menu-open');
    $('#nav-main').toggle();
  });
  $('#search-trigger').on('click', function() {
    $(this).toggleClass('open');
    $('.search-wp').toggleClass('open');
  });
}

// Navigation size
function nav_size() {
  var position = $(window).scrollTop();
  if (position > 220) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
}

// Navigation sub menu
function nav_sub_menu() {
  var viewport = $(window).width();
  $('#nav-main li[data-navsub] > a').on('click', function(event) {
    var navSub = $(this).parent();
    var isFocus = navSub.hasClass('focus');
    $('#nav-main li[data-navsub]').removeClass('focus');
    if (!isFocus) {
      navSub.addClass('focus');
    }
    event.stopPropagation();
    return false;
  });
  $('html').click(function() {
    $('#nav-main li[data-navsub]').removeClass('focus');
  });
}

// Scroll top button
function scroll_top() {
  $('#btn-back-top').fadeOut(0);
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('#btn-back-top').fadeIn(300);
    } else {
      $('#btn-back-top').fadeOut(300);
    }
  });
  $('#btn-back-top').on('click', function() {
    $('html, body').animate({
      scrollTop: 0
    }, '500');
    return false;
  });
}

function faq_toggle() {
  $('.item-faq .content').hide();
  $('.item-faq .button').on('click', function() {
    var isOpen = $(this).parents().eq(1).hasClass('open');
    if (isOpen) {
      $('.item-faq .content').hide();
      $('.item-faq').removeClass('open');
    } else {
      $('.item-faq .content').hide();
      $('.item-faq').removeClass('open');
      $(this).parent().next().toggle();
      $(this).parents().eq(1).toggleClass('open');
    }
    return false;
  });
}

function filter_toggle() {
  $('#open-list-filter').on('click', function() {
    $('#filter-wp').addClass('open');
    $(this).addClass('close')
  });
  $('#close-list-filter').on('click', function() {
    $('#filter-wp').removeClass('open');
    $('#open-list-filter').removeClass('close')
  });
}

// Initialisation
nav_mobile();
nav_sub_menu();
nav_size();
scroll_top();
faq_toggle();
filter_toggle();

import 'slick-carousel'


/*$('.vehicules').slick({
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 996,
            settings: "unslick"
        }
    ]
});*/

$('.vehicules-block-wp').slick({
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '<button class="arrow-prev"><span class="sprite carousel-prev mr-15"></span></button>',
    nextArrow: '<button class="arrow-next"><span class="sprite carousel-next ml-15"></span></button>',
    responsive: [
        {
            breakpoint: 996,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 780,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});
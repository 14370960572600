// JS dependency
var stickykit = require('sticky-kit/dist/sticky-kit.js');

// Initialisation
function stickykit_init() {
  var viewport = $(window).width();
  var offset = $('#header').innerHeight()+15;
  var except = $('#sticky-wp').hasClass('jobs-filters-sticky');
  if (viewport > 768) {
    $("#sticky-wp").stick_in_parent({
      offset_top: except ? offset - 17 : offset
    });
  } else {
    $("#sticky-wp").trigger("sticky_kit:detach");
  }
}

stickykit_init();

$(window).on('resize', function() {
  stickykit_init();
});
